// @use "@ag-grid-community/styles" as ag;

@import '@angular/cdk/overlay-prebuilt.css';
// @import '@ag-grid-community/styles/ag-grid.css';
// @import '@ag-grid-community/styles/ag-theme-alpine.css';

@import './app/styles/buttons';
@import './app/styles/mixins';

html {
  scroll-behavior: smooth;
}

// it is necessary for ng-select in order to use correct position for dropdowns inside ag-grid tables
body {
  position: relative;
}

#jsd-widget {
  left: 10px;
  right: unset !important;
  transition: bottom 0.3s !important;
}

.aux-container {
  width: 1100px;
}

.compare-dropdown {
  z-index: 1 !important;
}

div.ng-placeholder {
  color: var(--aux-black) !important;
}

.shadow-sticky {
  box-shadow: 0 2px 4px rgba(102, 102, 102, 0.5);
}

.btn--primary,
.btn--secondary,
.btn--success,
.btn--negative,
.btn--white {
  &:disabled,
  &:disabled:hover {
    outline: none;
    box-shadow: none;
    cursor: default;
  }
}

.btn--white:disabled {
  opacity: 0.5;
}

.btn--blue:disabled:hover {
  background-color: var(--aux-blue);
}

@include ag-grid-theme();

.ag-theme-alpine {
  .ag-row {
    font-size: 0.75rem;
  }

  .ag-root-wrapper {
    border-color: transparent;
  }

  .ag-row-odd:not(.ag-row-hover):not(.ag-row-selected):not(.remove-row) {
    background-color: white;
  }

  .ag-header-cell-text {
    letter-spacing: 0.14px;
  }

  .ag-root-wrapper-body {
    border-radius: 5px;
  }

  .ag-header {
    border-radius: 5px 5px 0 0;
    border: 1px solid var(--aux-gray-light);
    border-bottom-width: 2px;
  }

  .ag-header-cell-text {
    white-space: normal;
  }

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 8px;
    padding-right: 8px;
  }

  $range-selection-dark-border-color: rgba(33, 150, 243, 0.2);
  $range-selection-blue-border-color: #2196f3;

  .ag-cell.ag-cell-range-selected.ag-cell.focus {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, $range-selection-dark-border-color)
    ) !important;
  }

  .ag-cell.ag-cell-range-selected.ag-cell.focus.ag-cell-range-single-cell {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, $range-selection-dark-border-color)
    ) !important;
    border-width: 1px !important;
    border-color: var(--ag-range-selection-border-color) !important;
  }

  $directions: ('top', 'left', 'bottom', 'right');

  @each $direction in $directions {
    .ag-cell.ag-cell-range-selected.ag-cell-focus.ag-cell-range-#{$direction} {
      border-#{$direction}-width: 1px !important;
      border-#{$direction}-color: $range-selection-blue-border-color !important;
    }

    .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-#{$direction} {
      border-#{$direction}-width: 1px !important;
      border-#{$direction}-color: $range-selection-blue-border-color !important;
    }

    .ag-cell.ag-selection-fill-#{$direction},
    .ag-cell.ag-selection-fill-#{$direction}.ag-cell-range-selected {
      border-#{$direction}: 1px !important;
      border-#{$direction}-color: $range-selection-blue-border-color !important;
    }

    .ag-cell.ag-selection-fill-#{$direction},
    .ag-cell.ag-selection-fill-#{$direction}.ag-cell-range-selected {
      border-#{$direction}: 1px dashed !important;
      border-#{$direction}-color: #2196f3 !important;
    }
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, $range-selection-dark-border-color)
    ) !important;
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-single-cell {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, $range-selection-dark-border-color)
    ) !important;
    border-width: 1px !important;
    border-color: $range-selection-blue-border-color !important;
  }

  .ag-cell.ag-cell-range-selected.ag-cell-focus {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
    ) !important;
  }

  .ag-cell.text-right {
    display: inline-block;
    text-align: right;
  }

  .ag-cell {
    border-color: var(--aux-gray-light);
    border-width: 1px !important;
    display: flex;
    align-items: center;

    & .ag-icon {
      color: #181d1f;
    }
  }

  .ag-row-last > .ag-cell {
    &:first-child {
      border-radius: 0 0 0 5px;
    }
    &:last-child {
      border-radius: 0 0 5px 0;
    }
  }

  .ag-header-align-center .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header-align-center .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-header-group-cell.ag-invisible,
  .ag-header-cell.ag-invisible {
    background: none;
    padding: 0;
  }

  .ag-header-align-right .ag-header-cell-label {
    justify-content: flex-end;
  }

  .ag-header-align-right .ag-header-group-cell-label {
    justify-content: flex-end;
  }

  .ag-cell-align-right {
    @include ag-cell-align(flex-end, right);
  }

  .ag-cell-align-left {
    @include ag-cell-align(flex-start, left);
  }

  .ag-cell.removed-row {
    background: rgba(178, 16, 29, 0.33) !important;
  }
}

.ag-theme-aux {
  @extend .ag-theme-alpine;

  --ag-header-background-color: transparent;
  --ag-header-cell-hover-background-color: transparent;

  .ag-header-group-cell:not(.ag-column-resizing)
    + .ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:not(.ag-column-resizing)
    + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:first-of-type.ag-column-resizing {
    background-color: var(--aux-blue-dark);
  }

  .ag-header-group-cell-no-group:hover {
    background-color: var(--aux-gray-light) !important;
  }

  .gray-dark-header-group:hover {
    background-color: var(--aux-gray-dark) !important;
  }

  .ag-header-cell-comp-wrapper {
    justify-content: inherit;
  }

  .ag-header-cell:not(.ag-column-resizing)
    + .ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-resizing)
    + .ag-header-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
    background-color: var(--aux-gray-light);
  }

  .ag-theme-aux .ag-header-group-cell {
    background-color: var(--aux-blue-dark);
  }

  .ag-root-wrapper.ag-layout-auto-height {
    overflow: visible;
  }

  .ag-rich-select-list {
    max-height: 273px;
    height: auto;
  }

  .ag-rich-select-value {
    height: 35px;
  }

  .ag-popup-editor {
    margin-top: 2px;
  }

  .ag-cell-align-left {
    @include ag-cell-align(flex-start, left);
  }

  .ag-cell-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .ag-cell.ag-cell-edit-mode {
    border: 1px solid var(--aux-gray-dark) !important;
  }

  .ag-cell.ag-cell-not-edit-cell {
    opacity: 0.7;
    border: 0.5px solid rgba(186, 202, 208, 0.7) !important;
  }

  .ag-group-contracted,
  .ag-group-expanded {
    height: 100% !important;
    margin-right: 5px !important;
  }

  .ag-ltr .ag-header-select-all {
    margin-right: 0;
  }

  @for $i from 1 to 20 {
    .ag-row-group-indent-#{$i} {
      padding-left: $i * 10px;
    }

    .ag-row-level-#{$i} .ag-row-group-leaf-indent {
      margin-left: 10px;
    }
  }

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-cell.ag-cell-range-selected.ag-cell-focus {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
    ) !important;
  }

  .ag-cell.ag-cell-range-selected.ag-cell-focus.ag-cell-range-single-cell {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
    ) !important;
    border-width: 1px !important;
    border-color: var(--ag-range-selection-border-color) !important;
  }

  $directions: ('top', 'left', 'bottom', 'right');

  @each $direction in $directions {
    .ag-cell.ag-cell-range-selected.ag-cell-focus.ag-cell-range-#{$direction} {
      border-#{$direction}-width: 1px !important;
      border-#{$direction}-color: #2196f3 !important;
    }

    .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-#{$direction} {
      border-#{$direction}-width: 1px !important;
      border-#{$direction}-color: #2196f3 !important;
    }

    .ag-cell.ag-selection-fill-#{$direction},
    .ag-cell.ag-selection-fill-#{$direction}.ag-cell-range-selected {
      border-#{$direction}: 1px dashed !important;
      border-#{$direction}-color: #2196f3 !important;
    }
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected:not(.ag-cell-range-single-cell) {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
    ) !important;
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-single-cell {
    background-color: var(
      --ag-range-selection-background-color-1,
      var(--ag-range-selection-background-color, rgba(33, 150, 243, 0.2))
    ) !important;
    border-width: 1px !important;
    border-color: #2196f3 !important;
  }

  .ag-header-icon.ag-header-label-icon.ag-filter-icon:not(.ag-hidden) {
    color: var(--aux-gray-darkest) !important;
  }

  .ag-header-icon .ag-icon {
    color: var(--aux-gray-darkest) !important;
  }

  .ag-header-group-cell.bg-aux-gray-dark {
    background-color: var(--aux-gray-dark);
  }

  .ag-header-group-cell.aux-black {
    color: var(--aux-black);
  }

  .ag-header-group-cell.border-aux-gray-dark-100 {
    border-color: var(--aux-gray-dark-100);
  }

  .ag-header-group-cell.border-0 {
    border: 0;
  }

  .ag-header {
    border: none;
  }

  .ag-header-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 0.5px solid var(--aux-gray-dark);
    background: var(--aux-gray-light);

    &.whitespace-nowrap .ag-header-cell-text {
      white-space: nowrap;
    }

    .ag-header-cell-text {
      white-space: normal;
      text-align: center;
    }
  }

  .ag-header-cell.ag-header-edit-mode {
    background: var(--aux-gray-dark);
    border-color: var(--aux-gray-dark-100);
  }

  .ag-header-group-cell.ag-invisible,
  .ag-header-cell.ag-invisible,
  .ag-cell.ag-invisible {
    padding: 0;
  }

  .ag-root-wrapper {
    border: none;
  }
  .ag-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 33px;
  }

  .ag-row {
    &.has-error .ag-cell {
      background-color: var(--aux-error) !important;

      .text-aux-error,
      .ag-icon {
        color: white;
      }
    }
  }

  .ag-row {
    .ag-cell.has-cell-error {
      background-color: var(--aux-error) !important;

      p {
        color: white;
      }

      &.ag-cell-range-selected p {
        color: black;
      }

      &.ag-cell-focus:not(.ag-cell-range-selected) p {
        color: white;
      }
    }
  }

  .ag-row,
  .ag-cell-inline-editing {
    font-size: 14px;
    height: 34px;
  }

  .ag-theme-aux .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 100%;
  }

  .ag-header-group-cell {
    background-color: var(--aux-blue-dark);
    color: white;
    border: 0.5px solid var(--aux-gray-light);
  }

  .ag-cell,
  .ag-row-group > .ag-cell-expandable {
    border: 0.5px solid var(--aux-gray-dark) !important;
    color: var(--aux-black);

    .ag-icon {
      color: var(--aux-black);
    }
  }

  .ag-cell,
  .ag-header-cell {
    text-align: right;
    .ag-checkbox-input-wrapper.ag-checked::after,
    .ag-theme-aux .ag-checkbox-input-wrapper.ag-checked::after,
    .ag-indeterminate::after {
      color: rgba(79, 70, 229, 1);
    }
  }

  .ag-cell-expandable {
    text-align: left;
  }

  .ag-cell.cell-justify-center {
    display: flex;
    justify-content: center;
  }

  .ag-cell.cell-align-center {
    display: flex;
    align-items: center;
  }

  .ag-cell.text-left {
    text-align: left;
  }

  .ag-cell.border-aux-error {
    border: 2px solid var(--aux-error) !important;
  }

  .ag-row,
  .ag-row-odd,
  .ag-row-even {
    &.is-odd {
      .ag-cell,
      .ag-cell-expandable {
        &:not(.bg-aux-error) {
          background: white;
        }
      }
    }

    &.is-even {
      .ag-cell,
      .ag-cell-expandable {
        &:not(.bg-aux-error) {
          background: var(--aux-gray-light);
        }
      }
    }

    .ag-invisible {
      background: none !important;
    }
  }

  .ag-row-odd .ag-cell:not(.bg-aux-error) {
    background: var(--aux-gray-light);
  }

  .ag-row-even .ag-cell:not(.bg-aux-error) {
    background: white;
  }

  .ag-row:hover,
  .ag-row-hover,
  .ag-row.ag-row-selected {
    &:not(.has-error) {
      .ag-cell,
      .ag-cell-expandable {
        &:not(.bg-aux-error):not(.ag-cell-highlight):not(.has-cell-error):not(.removed-row) {
          background: var(--aux-blue-light-50) !important;
        }
      }
      .ag-cell.ag-invisible {
        background: none !important;
      }
    }
  }

  .ag-header-cell-resize::after {
    background: none;
  }

  .ag-tooltip {
    background: white;
  }
  .ag-group-value {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  .ag-header-align-center {
    .ag-header-cell-label,
    .ag-header-group-cell-label {
      justify-content: center;
    }
  }

  .ag-header-clamp {
    .ag-header-cell-text {
      @apply line-clamp-2 pr-1;
    }
  }

  .ag-header-truncate {
    .ag-header-cell-text {
      @apply truncate;
    }
  }

  .ag-pinned-right-header {
    border-left-width: 0;
  }

  .ag-floating-bottom {
    border-top-color: var(--aux-gray-darkest);
    font-weight: 700;
  }

  aux-ag-adjustment-evidence-based-header {
    width: 100%;
  }

  .ag-cell.removed-row {
    background: rgba(178, 16, 29, 0.33) !important;
  }
}

.ag-theme-alpine,
.ag-theme-aux {
  .ag-header-cell-comp-wrapper:not(.ag-right-aligned-header) {
    & .ag-sort-ascending-icon,
    & .ag-sort-descending-icon {
      color: transparent;
      margin-left: 0;
    }
  }

  .ag-row .ag-cell.ag-cell-range-selected.ag-cell-highlight {
    background-color: #2196f3 !important;
  }

  .ag-paging-panel {
    color: var(--aux-gray-darkest);
    border: 0;
    height: 48px;
  }

  .ag-sort-ascending-icon {
    background: transparent url('/assets/svg/arrow-narrow-up.svg') center/contain no-repeat;
  }

  .ag-sort-descending-icon {
    background: transparent url('/assets/svg/arrow-narrow-down.svg') center/contain no-repeat;
  }
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-scroll-hide-scrollbar {
  overflow: scroll;
}

.overflow-scroll-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#forecast-table {
  .border-bottom-aux-gray {
    border-bottom-color: #bacad0 !important;
  }

  .background-white {
    background: white !important;
  }

  .background-light {
    background: var(--aux-gray-light) !important;
  }

  .ag-row-group .ag-cell {
    border: 0 !important;
  }

  .ag-row-group {
    .ag-icon,
    .ag-group-value {
      font-weight: 700;
    }
  }

  .ag-cell.background-white {
    border-width: 1px !important;
    border-color: rgb(243 246 247 / 100%) !important;
  }

  .ag-cell.background-light {
    border-width: 1px !important;
    border-color: rgb(237 240 242) !important;
  }

  .ag-row:hover,
  .ag-row-hover {
    .ag-cell {
      background: var(--aux-blue-light-50) !important;
    }
  }

  span.ag-cell-value {
    overflow: visible;
  }
}

.show-placeholder {
  .ng-placeholder {
    display: unset !important;
  }
  .ng-value {
    display: none;
  }
}
.respect_newline {
  white-space: pre-line;
}

/**
 * ==============================================
 * Dot Flashing
 * From https://github.com/nzbin/three-dots
 * If we think it's useful, we can consider installing
 * the library so we get the other animations.
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #095b95;
  color: #095b95;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -6px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #095b95;
  color: #095b95;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 6px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #095b95;
  color: #095b95;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #095b95;
  }

  50%,
  100% {
    background-color: #1c8dde55;
  }
}
