@use "@ag-grid-community/styles" as ag;

@mixin ag-cell-align($flex-justify, $text-align) {
  justify-content: $flex-justify !important;
  text-align: $text-align !important;
}

@mixin custom-option-template {
  .ng-dropdown-panel-items {
    display: none !important;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    border-top: 0;
  }

  .items-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }
}

@mixin ag-grid-theme {
  $gray-light: var(--aux-gray-light);
  $gray-dark: var(--aux-gray-dark);
  $black: var(--aux-black);
  $indent-size: 10px;
  $border-color: 'transparent';
  $horizontal-border: solid $gray-dark;

  @include ag.grid-styles(
    (
      theme: alpine,
      --ag-font-family: (
        'Inter',
        sans-serif,
      ),
      --ag-input-disabled-background-color: $gray-light,
      --ag-input-disabled-border-color: $gray-dark,
      --ag-secondary-border-color: $gray-dark,
      --ag-cell-horizontal-border: $horizontal-border,
      --ag-row-border-color: transparent,
      --ag-font-size: 14px,
      --ag-header-cell-hover-background-color: transparent,
      --ag-header-background-color: $gray-light,
      --ag-header-column-resize-handle-color: $gray-dark,
      --ag-data-color: #000,
      --ag-header-foreground-color: $black,
      --ag-secondary-foreground-color: $gray-light,
      --ag-row-group-indent-size: $indent-size,
      --ag-icon-font-family: agGridAlpine,
    )
  );

  .ag-row {
    border: none;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-cols-container,
  .ag-row-group-expanded,
  .ag-row-group-contracted {
    .ag-cell-value.ag-cell,
    .ag-row {
      display: block;
    }
  }
}
