/*! purgecss start ignore */

/*
.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}
*/
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent var(--text);
  border-width: 0 5px 5px;
}
/*
  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  }
  */
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  background-color: #fff;
  border-radius: 5px;
  min-height: 35px;
  @apply border items-center;
}
/*
  .ng-select .ng-select-container:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  */
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 10px;
}
[dir='rtl'] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999;
}
.ng-select.ng-select-single .ng-select-container {
  min-height: 35px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}
[dir='rtl'] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 10px;
  padding-left: 50px;
}
.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}
.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.9em;
  margin-bottom: 5px;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  padding-left: 5px;
}
[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: #d1e8ff;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}
[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.right {
  border-left: 1px solid #b8dbff;
}
[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-bottom: 5px;
  padding-left: 3px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}
.ng-select .ng-clear-wrapper {
  color: #999;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #d0021b;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
[dir='rtl'] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}
.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}
[dir='rtl'] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.ng-dropdown-panel {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  @apply border left-0 bg-white;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  @apply mt-2 rounded-md;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  @apply rounded-b-md;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:first-child {
  @apply rounded-t-md;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  @apply rounded-t-md;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--aux-gray-light);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  @apply font-medium;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-gray-200;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}
[dir='rtl'] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.ng-option {
  position: relative;
}

.ng-option-label {
  @apply text-sm;
}

.ng-option-selected::after {
  display: block;
  position: absolute;
  content: ' ';
  background-image: url(/assets/svg/tick.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  height: 14px;
  width: 14px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.aux-select {
  label {
    @apply block mb-1 text-xs;
  }

  .ng-select-container {
    @apply h-12;
  }
}

/*! purgecss end ignore */
