/*! purgecss start ignore */
@layer components {
  .spinner {
    @apply ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 animate-spin;
    border-top-color: var(--aux-blue);
  }

  .aux-link,
  .aux-link .ag-group-value {
    @apply font-medium text-aux-blue underline;
  }

  .aux-link.aux-link--black {
    @apply text-aux-gray-darkest;
  }

  .aux-link-zero-hyphen .aux-link-zero-hyphen .ag-group-value {
    @apply font-medium text-aux-blue;
  }

  .btn {
    @apply inline-flex justify-center items-center px-4 py-2 border shadow-sm font-medium rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-aux-blue-light focus:outline-none;
  }

  .btn-nr {
    @apply inline-flex justify-center items-center px-4 py-2 border shadow-sm font-medium rounded-full focus:outline-none;
  }

  .btn-ds {
    @apply inline-flex justify-center items-center px-4 py-2 font-medium rounded-full focus:outline-none;
  }

  .btn-ds:active {
    @apply shadow-inner outline-none;
  }

  .btn--blue {
    @apply border-transparent text-white bg-aux-blue hover:bg-aux-blue-light;
  }

  .aux-link:disabled,
  .btn--blue:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .btn--primary {
    @apply text-sm btn-ds btn--blue hover:bg-aux-blue-hover;
  }

  .btn--secondary {
    @apply text-sm btn-ds text-aux-blue border border-aux-blue hover:ring-1 hover:ring-aux-blue bg-white;
  }

  .btn--negative {
    @apply text-sm btn-ds text-white bg-aux-red-dark hover:bg-aux-red-hover;
  }

  .btn--success {
    @apply text-sm btn-ds text-white bg-aux-green hover:bg-aux-green-hover;
  }

  .btn--primary:disabled {
    @apply opacity-50 cursor-not-allowed hover:bg-aux-blue;
  }

  .btn--primary:active {
    @apply bg-aux-blue;
  }

  .btn--secondary:disabled {
    @apply opacity-50 cursor-not-allowed hover:bg-white hover:ring-0;
  }

  .btn--negative:disabled {
    @apply opacity-50 cursor-not-allowed hover:bg-aux-error;
  }

  .btn--negative:active {
    @apply bg-aux-error;
  }

  .btn--success:disabled {
    @apply opacity-50 cursor-not-allowed hover:bg-aux-green;
  }

  .btn--success:active {
    @apply bg-aux-green;
  }

  .btn--white {
    @apply border-2 border-aux-blue text-aux-blue hover:bg-gray-50;
  }

  .input,
  .select {
    @apply block h-12 mt-1 w-full border-aux-gray-dark font-medium rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500;
  }

  input:disabled,
  select:disabled {
    @apply opacity-75 cursor-not-allowed shadow-none;
  }

  .select__big .ng-select-container {
    @apply h-12;
  }

  .input.is-invalid {
    @apply ring-1 ring-aux-error border-aux-error;
  }

  .select.is-invalid:not(.ng-select-disabled) {
    @apply ring-2 ring-aux-error border-aux-error;
  }

  .label {
    @apply block font-medium text-lg;
  }

  [type='radio' i]:disabled:not(:checked) {
    @apply bg-gray-200 cursor-not-allowed;
  }
}

/*! purgecss end ignore */
